/*--------------------------------------------------------------
# admin
--------------------------------------------------------------*/
#admin {
  margin-top: 60px;
  padding: 20px 30px;
  transition: all 0.3s;
}

@media (max-width: 1199px) {
  #admin {
    padding: 20px;
  }
}
.table-dark {
  --bs-table-color: var(--bs-primary-text-emphasis);
  --bs-table-bg: #f6f6fe;
}
/*--------------------------------------------------------------
  # Page Title
  --------------------------------------------------------------*/
#admin .pagetitle {
  margin-bottom: 10px;
}

#admin .pagetitle h1 {
  font-size: 24px;
  margin-bottom: 0;
  font-weight: 600;
  color: #012970;
}

/*--------------------------------------------------------------
  # Override some default Bootstrap stylings
  --------------------------------------------------------------*/
/* Dropdown menus */
#admin .dropdown-menu {
  border-radius: 4px;
  padding: 10px 0;
  animation-name: dropdown-animate;
  animation-duration: 0.2s;
  animation-fill-mode: both;
  border: 0;
  box-shadow: 0 5px 30px 0 rgba(82, 63, 105, 0.2);
}

#admin .dropdown-menu .dropdown-header,
#admin .dropdown-menu .dropdown-footer {
  text-align: center;
  font-size: 15px;
  padding: 10px 25px;
}

#admin .dropdown-menu .dropdown-footer a {
  color: #444444;
  text-decoration: underline;
}

#admin .dropdown-menu .dropdown-footer a:hover {
  text-decoration: none;
}

#admin .dropdown-menu .dropdown-divider {
  color: #a5c5fe;
  margin: 0;
}

#admin .dropdown-menu .dropdown-item {
  font-size: 14px;
  padding: 10px 15px;
  transition: 0.3s;
}

#admin .dropdown-menu .dropdown-item i {
  margin-right: 10px;
  font-size: 18px;
  line-height: 0;
}

#admin .dropdown-menu .dropdown-item:hover {
  background-color: #f6f9ff;
}

@media (min-width: 768px) {
  #admin .dropdown-menu-arrow::before {
    content: "";
    width: 13px;
    height: 13px;
    background: #fff;
    position: absolute;
    top: -7px;
    right: 20px;
    transform: rotate(45deg);
    border-top: 1px solid #eaedf1;
    border-left: 1px solid #eaedf1;
  }
}

@keyframes dropdown-animate {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }

  0% {
    opacity: 0;
  }
}

/* Light Backgrounds */
#admin .bg-primary-light {
  background-color: #cfe2ff;
  border-color: #cfe2ff;
}

#admin .bg-secondary-light {
  background-color: #e2e3e5;
  border-color: #e2e3e5;
}

#admin .bg-success-light {
  background-color: #d1e7dd;
  border-color: #d1e7dd;
}

#admin .bg-danger-light {
  background-color: #f8d7da;
  border-color: #f8d7da;
}

#admin .bg-warning-light {
  background-color: #fff3cd;
  border-color: #fff3cd;
}

#admin .bg-info-light {
  background-color: #cff4fc;
  border-color: #cff4fc;
}

#admin .bg-dark-light {
  background-color: #d3d3d4;
  border-color: #d3d3d4;
}

/* Card */
#admin .card {
  margin-bottom: 30px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
}

#admin .card-header,
#admin .card-footer {
  border-color: #ebeef4;
  background-color: #fff;
  color: #798eb3;
  padding: 15px;
}

#admin .card-title {
  padding: 20px 0 15px 0;
  font-size: 18px;
  font-weight: 500;
  color: #012970;
  font-family: "Poppins", sans-serif;
}

#admin .card-title span {
  color: #899bbd;
  font-size: 14px;
  font-weight: 400;
}

#admin .card-body {
  padding: 0 20px 20px 20px;
}

#admin .card-img-overlay {
  background-color: rgba(255, 255, 255, 0.6);
}

/* Alerts */
#admin .alert-heading {
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
}

/* Close Button */
#admin .btn-close {
  background-size: 25%;
}

#admin .btn-close:focus {
  outline: 0;
  box-shadow: none;
}

/* Accordion */
#admin .accordion-item {
  border: 1px solid #ebeef4;
}

#admin .accordion-button:focus {
  outline: 0;
  box-shadow: none;
}

#admin .accordion-button:not(.collapsed) {
  color: #012970;
  background-color: #f6f9ff;
}

#admin .accordion-flush .accordion-button {
  padding: 15px 0;
  background: none;
  border: 0;
}

#admin .accordion-flush .accordion-button:not(.collapsed) {
  box-shadow: none;
  color: #4154f1;
}

#admin .accordion-flush .accordion-body {
  padding: 0 0 15px 0;
  color: #3e4f6f;
  font-size: 15px;
}

/* Breadcrumbs */
#admin .breadcrumb {
  font-size: 14px;
  font-family: "Nunito", sans-serif;
  color: #899bbd;
  font-weight: 600;
}

#admin .breadcrumb a {
  color: #899bbd;
  transition: 0.3s;
}

#admin .breadcrumb a:hover {
  color: #51678f;
}

#admin .breadcrumb .breadcrumb-item::before {
  color: #899bbd;
}

#admin .breadcrumb .active {
  color: #51678f;
  font-weight: 600;
}

/* Bordered Tabs */
#admin .nav-tabs-bordered {
  border-bottom: 2px solid #ebeef4;
}

#admin .nav-tabs-bordered .nav-link {
  margin-bottom: -2px;
  border: none;
  color: #2c384e;
}

#admin .nav-tabs-bordered .nav-link:hover,
#admin .nav-tabs-bordered .nav-link:focus {
  color: #4154f1;
}

#admin .nav-tabs-bordered .nav-link.active {
  background-color: #fff;
  color: #4154f1;
  border-bottom: 2px solid #4154f1;
}

/*--------------------------------------------------------------
  # Dashboard
  --------------------------------------------------------------*/
/* Filter dropdown */
#admin .dashboard .filter {
  position: absolute;
  right: 0px;
  top: 15px;
}

#admin .dashboard .filter .icon {
  color: #aab7cf;
  padding-right: 20px;
  padding-bottom: 5px;
  transition: 0.3s;
  font-size: 16px;
}

#admin .dashboard .filter .icon:hover,
#admin .dashboard .filter .icon:focus {
  color: #4154f1;
}

#admin .dashboard .filter .dropdown-header {
  padding: 8px 15px;
}

#admin .dashboard .filter .dropdown-header h6 {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #aab7cf;
  margin-bottom: 0;
  padding: 0;
}

#admin .dashboard .filter .dropdown-item {
  padding: 8px 15px;
}

/* Info Cards */
#admin .dashboard .info-card {
  padding-bottom: 10px;
}

#admin .dashboard .info-card h6 {
  font-size: 28px;
  color: #012970;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

#admin .dashboard .card-icon {
  font-size: 32px;
  line-height: 0;
  width: 64px;
  height: 64px;
  flex-shrink: 0;
  flex-grow: 0;
}

#admin .dashboard .sales-card .card-icon {
  color: #4154f1;
  background: #f6f6fe;
}

#admin .dashboard .revenue-card .card-icon {
  color: #2eca6a;
  background: #e0f8e9;
}

#admin .dashboard .customers-card .card-icon {
  color: #ff771d;
  background: #ffecdf;
}

/* Activity */
#admin #admin .dashboard .activity {
  font-size: 14px;
}

#admin .dashboard .activity .activity-item .activite-label {
  color: #888;
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
  min-width: 64px;
}

#admin .dashboard .activity .activity-item .activite-label::before {
  content: "";
  position: absolute;
  right: -11px;
  width: 4px;
  top: 0;
  bottom: 0;
  background-color: #eceefe;
}

#admin .dashboard .activity .activity-item .activity-badge {
  margin-top: 3px;
  z-index: 1;
  font-size: 11px;
  line-height: 0;
  border-radius: 50%;
  flex-shrink: 0;
  border: 3px solid #fff;
  flex-grow: 0;
}

#admin .dashboard .activity .activity-item .activity-content {
  padding-left: 10px;
  padding-bottom: 20px;
}

#admin .dashboard .activity .activity-item:first-child .activite-label::before {
  top: 5px;
}

#admin .dashboard .activity .activity-item:last-child .activity-content {
  padding-bottom: 0;
}

/* Recent Sales */
#admin .dashboard .recent-sales {
  font-size: 14px;
}

#admin .dashboard .recent-sales .table thead {
  background: #f6f6fe;
}

#admin .dashboard .recent-sales .table thead th {
  border: 0;
}

#admin .dashboard .recent-sales .dataTable-top {
  padding: 0 0 10px 0;
}

#admin .dashboard .recent-sales .dataTable-bottom {
  padding: 10px 0 0 0;
}

/* Top Selling */
#admin .dashboard .top-selling {
  font-size: 14px;
}

#admin .dashboard .top-selling .table thead {
  background: #f6f6fe;
}

#admin .dashboard .top-selling .table thead th {
  border: 0;
}

#admin .dashboard .top-selling .table tbody td {
  vertical-align: middle;
}

#admin .dashboard .top-selling img {
  border-radius: 5px;
  max-width: 60px;
}

/*--------------------------------------------------------------
  # Profie Page
  --------------------------------------------------------------*/
#admin .profile .profile-card img {
  max-width: 120px;
}

#admin .profile .profile-card h2 {
  font-size: 24px;
  font-weight: 700;
  color: #2c384e;
  margin: 10px 0 0 0;
}

#admin .profile .profile-card h3 {
  font-size: 18px;
}

#admin .profile .profile-card .social-links a {
  font-size: 20px;
  display: inline-block;
  color: rgba(1, 41, 112, 0.5);
  line-height: 0;
  margin-right: 10px;
  transition: 0.3s;
}

#admin .profile .profile-card .social-links a:hover {
  color: #012970;
}

#admin .profile .profile-overview .row {
  margin-bottom: 20px;
  font-size: 15px;
}

#admin .profile .profile-overview .card-title {
  color: #012970;
}

#admin .profile .profile-overview .label {
  font-weight: 600;
  color: rgba(1, 41, 112, 0.6);
}

#admin .profile .profile-edit label {
  font-weight: 600;
  color: rgba(1, 41, 112, 0.6);
}

#admin .profile .profile-edit img {
  max-width: 120px;
}

/*--------------------------------------------------------------
  # Contact
  --------------------------------------------------------------*/
#admin .contact .info-box {
  padding: 28px 30px;
}

#admin .contact .info-box i {
  font-size: 38px;
  line-height: 0;
  color: #4154f1;
}

#admin .contact .info-box h3 {
  font-size: 20px;
  color: #012970;
  font-weight: 700;
  margin: 20px 0 10px 0;
}

#admin .contact .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

#admin .contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

#admin .contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

#admin .contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
}

#admin .contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  animation: animate-loading 1s linear infinite;
}

#admin .contact .php-email-form input,
#admin .contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  border-radius: 0;
}

#admin .contact .php-email-form input:focus,
#admin .contact .php-email-form textarea:focus {
  border-color: #4154f1;
}

#admin .contact .php-email-form input {
  padding: 10px 15px;
}

#admin .contact .php-email-form textarea {
  padding: 12px 15px;
}

#admin .contact .php-email-form button[type="submit"] {
  background: #4154f1;
  border: 0;
  padding: 10px 30px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}

#admin .contact .php-email-form button[type="submit"]:hover {
  background: #5969f3;
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
